@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}
/* CustomScrollBar.css */
.custom-scroll-bar {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.scroll-content {
  width: calc(100% + 17px); /* Adjust for scrollbar width */
  height: 100%;
  overflow-y: scroll;
  padding-right: 5px; /* Adjust for scrollbar width */
}

/* Style your scrollbar */
.scroll-content::-webkit-scrollbar {
  width: 8px;
}

.scroll-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}


.blue-text {
  /* color: #30A5FF; */
  color: #57AAFF;
  cursor: pointer;
}

.blue-text:hover {
  text-decoration: underline;
}

.vertical-line {
  border-right: 1px solid #ccc; /* Adjust the color and width of the border as needed */
}

.select-fix {
  /* Adjust styles to prevent clipping */
  overflow: visible; /* Ensures options aren't clipped */
  /* Additional styles (optional): */
  padding: 5px 10px; /* Adjust padding if needed */
}

.table-responsive{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1210px;
  overflow-x: auto;
}

@media (max-width: 1080px) {
  .table-responsive {
    width: 100% !important;
    max-width: 1250px !important;
  }
}
@media (min-width: 742px) {
  .table-responsive {
    width: 100% !important;
    max-width: 1100px  !important;
  }
}

